import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Container from '../../components/Container';
import { Desktop } from '../../constants/responsive';
import { css } from '@emotion/core';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import GeneralBanner from '../../components/GeneralBanner';

const DownloadItem = ({ name, file, type = 'pdf' }) => {
  const intl = useIntl();
  const locale = intl.locale;
  const localeMap = { en: 'en', 'zh-cn': 'zh_cn', 'zh-hk': 'zh_tw' };
  return (
    // <a href={`/legal/${file}-${locale}.${type}`} target="_blank">
    <a
      href={`https://oss-static.aqumon.com/pdf/pdfjs/web/viewer.html?file=/pdf/${file}${localeMap[locale]}.${type}`}
      target='_blank'
    >
      <h3
        css={css`
          margin-bottom: 10px;
        `}
      >
        <FormattedHTMLMessage id={`legal.${name}`} />
      </h3>
    </a>
  );
};

const LegalPage = () => {
  const intl = useIntl();
  return (
    <Layout footerBlank>
      <SEO
        title={intl.formatMessage({ id: 'legal.title' })}
        description={intl.formatMessage({ id: 'legal.description' })}
        keywords={intl.formatMessage({ id: 'legal.keywords' })}
      />
      <GeneralBanner>
        <FormattedHTMLMessage id='legal.title' />
      </GeneralBanner>
      <Container
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: 80px;
          ${Desktop} {
            margin-top: 100px;
          }
        `}
      >
        <h1>
          <FormattedHTMLMessage id='legal.service' />
        </h1>
        {/* <DownloadItem name='client' file='AQUMON-customer-aggrement-' /> */}
        <DownloadItem name='client' file='customer_agreement_' />
        <DownloadItem name='stock-agreement' file='AQUMON-stock-agreement-' />
        <DownloadItem name='disclosure' file='AQUMON-full-disclosure-' />
        <div
          css={css`
            height: 40px;
          `}
        />
        <h1>
          <FormattedHTMLMessage id='legal.general' />
        </h1>
        <DownloadItem name='terms' file='AQUMON-terms-of-use-' />
        <DownloadItem name='disclaimer' file='AQUMON-disclaimer-' />
        <DownloadItem name='privacy' file='AQUMON-privacy-policy-' />
        <DownloadItem name='copyright' file='AQUMON-copyright-policy-' />
      </Container>
    </Layout>
  );
};

export default LegalPage;
